/* HEADER */

.header {
    box-shadow: 0 0 12px var(--dark-color);
}

/* HEADER CONTENT */

.header-content {
    height: 100px;
}

.header-content .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.header-content .mobile-input-button {
    display: none;
}

.header-content .mobile-input-button button i {
    color: var(--light-color);
    font-size: 30px;
}

.header-content .mobile-input-button input {
    position: absolute;
    left: 10px;
    width: calc(100% - 20px);
    padding: 5px 10px 5px 40px;
    z-index: 80;
    opacity: 0;
    visibility: hidden;
    border: none;
    font-size: 18px;
    height: 40px;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    background: var(--primary-color);
    color: var(--light-color);
}

.header-content .mobile-input-button input::placeholder {
    color: var(--light-color) !important;
}

.header-content .mobile-input-button input::-ms-input-placeholder {
    color: var(--light-color) !important;
}

.header-content .mobile-input-button .input-block-touch {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80;
    height: 107px;
    width: 100%;
    display: none;
}

.header-content .mobile-input-button.active .input-block-touch {
    display: block;
}

.header-content .mobile-input-button.active input {
    visibility: visible;
    opacity: 1;
}

.header-content .mobile-input-button .mobile-back-button {
    position: absolute;
    left: 10px;
    z-index: 90;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
}

.header-content .mobile-input-button.active .mobile-back-button {
    visibility: visible;
    opacity: 1;
}

/* OPEN MENU BUTTON */

.open-menu-button {
    display: none;
}

.open-menu-button i {
    color: var(--light-color);
    font-size: 35px;
}

/* LOGO */

.logo {
    display: flex;
}

.logo a {
    display: flex;
}

.logo a .logo-content {
    display: flex;
    font-size: 0;
}

.logo svg {
    width: 200px;
}

/* MENU */

.menu {
    background: #6a2c70;
    padding: 20px 0;
}

.menu .container {
    color: var(--light-color);
    padding: 0 10px;
}

.menu-header {
    display: none;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
    margin-bottom: 15px;
}

.category-menu-title {
    display: none;
    font-weight: bold;
    color: var(--primary-color);
}

.category-menu-title svg {
    width: 170px;
    height: 60px;
}

.close-menu-button i {
    font-size: 35px;
    color: var(--primary-color);
}

/* CATEGORY MENU ITEMS */

.category-menu-items {
    display: flex;
    width: 100%;
    max-width: 800px;
    justify-content: space-between;
    color: var(--light-color);
}

.category-menu-item a {
    color: var(--light-color);
}

@media (max-width: 800px) {
    .menu {
        position: fixed;
        background: var(--light-color);
        color: var(--primary-color);
        top: 0;
        height: 100%;
        width: 70%;
        box-shadow: -4px 0 20px #474747;
        z-index: 10;
        transition: all 0.5s ease;
        left: -100%;
        padding-top: 0;
    }

    .menu.active {
        left: 0;
    }

    .open-menu-button {
        display: flex;
    }

    .header-content .input-button {
        display: none;
    }

    .header-content .mobile-input-button {
        display: flex;
        align-items: center;
    }

    .menu .menu-header {
        display: flex;
        margin-bottom: 0;
        height: 100px;
    }

    .menu ul li {
        font-size: 20px;
    }

    .menu .category-menu-title {
        display: flex;
        justify-content: center;
    }

    .menu .category-menu-items {
        border-top: 2px #6a2c700d solid;
        padding-top: 20px;
    }

    .menu ul {
        display: block;
    }

    .menu ul li a {
        display: flex;
        color: var(--primary-color);
        width: 100%;
        padding-bottom: 10px;
    }

    .header-content {
        background: var(--primary-color);
    }
}