.horoscope-area {
    padding: 40px 10px;
}

.horoscope-area h2 {
    color: var(--primary-color);
    font-size: 40px;
}

.horoscope-form {
    margin-bottom: 20px;
}

.field-description {
    font-weight: bold;
    padding: 20px 0;
}

.field-description label {
    color: var(--primary-color);
}

.field-options {
    display: flex;
    gap: 20px;
    padding-bottom: 10px;
}

.field-options.signs {
    gap: 0px;
}

.field-options button {
    padding: 10px 15px;
    border-radius: 5px;
    color: var(--primary-color);
    width: 100%;
    font-weight: bold;
    border: 1px var(--primary-color) solid;
    font-size: 15px;
}

.field-options button:hover {
    background: var(--primary-color);
    color: var(--light-color);
}

.field-options button.active {
    background: var(--primary-color);
    color: var(--light-color);
}

.field-options button.sign-button {
    border: none;
    background: none;
    display: block;
    color: var(--primary-color);
    transition: all .2s ease;
    padding: 10px 5px;
}

.field-options button.sign-button:hover {
    transform: scale(1.13);
}

.field-options button.sign-button.active:hover {
    transform: scale(1);
}

.field-options button.sign-button.active {
    background: var(--primary-color);
    color: var(--light-color);
}

.field-options button.sign-button img {
    width: 50px;
}

.form-field select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 10px 15px;
    border-radius: 5px;
    color: var(--primary-color);
    width: 100%;
    font-weight: bold;
    border: 1px var(--primary-color) solid;
    background: var(--background);
    height: 42px;
    display: none;
    font-size: 15px;
}

.form-field select.show {
    display: block;
}

.btn-get-horoscope, .btn-get-new-check {
    background: var(--primary-color);
    border-radius: 5px;
    gap: 5px;
    color: var(--light-color);
    font-weight: bold;
    max-width: 1000px;
    width: 100%;
    padding: 10px 15px;
    font-size: 15px;
}

.btn-get-horoscope:disabled {
    background: #a696a7;
}

.btn-get-horoscope i {
    font-size: 18px;
    color: var(--light-color);
}

.btn-get-new-check {
    display: none;
}

.btn-get-new-check.show {
    display: flex;
}

.btn-get-new-check i {
    font-size: 18px;
    color: var(--light-color);
}

.horoscope-result {
    padding: 20px 0 20px 0;
}

.horoscope-result h3 {
    margin-bottom: 5px;
}

.horoscope-result {
    display: none;
}

.horoscope-result.show {
    display: block;
}

@media (max-width: 800px) {
    .field-options {
        overflow: auto;
    }
}