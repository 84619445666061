.article-card {
    margin-bottom: 20px;
    border-radius: 3px;
    background: #fff;
}

.article-card.inside-text {
    position: relative;
}

.article-card a {
    display: block;
    height: 100%;
    width: 100%;
}

.article-card.inline a {
    display: flex;
}

.article-card .article-card-image {
    height: 100%;
    max-height: 200px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inline .article-card-image {
    max-width: 240px;
    min-width: 240px;
}

.article-card .article-card-image img {
    width: 100%;
    height: calc(75vh - 530px);
    object-fit: cover;
    transition: all 0.2s ease;
}

.article-card:hover .article-card-image img {
    transform: scale(1.03);
}

.article-card-content {
    padding: 15px;
}

.article-card-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 110%;
    margin: 0;
    margin-bottom: 10px;
}

.article-card-date {
    color: var(--light-font-color);
    font-size: 12px;
    margin: 0;
}

.article-card.inside-text .article-card-image {
    min-height: 33vh;
    width: 100%;
}

.article-card.inside-text .article-card-image img {
    height: auto;
    min-height: 33vh;
}

.article-card.inside-text .article-card-content {
    position: absolute;
    bottom: 0;
    transition: all 0.2s ease;
    padding: 20px;
}

.article-card.inside-text:hover .article-card-content {
    transform: scale(1.02);
}

.article-card.inside-text .article-card-content .article-card-title,
.article-card.inside-text .article-card-content p {
    color: var(--light-color);
}

.article-card.inside-text .article-card-content .article-card-title {
    font-size: 24px;
}

@media (max-width: 1000px) {
    .article-card-title {
        font-weight: bold;
        font-size: 14px;
        line-height: 110%;
        margin: 0;
        margin-bottom: 5px;
    }

    .article-card .article-card-image  {
        max-width: 230px;
        min-width: 230px;
    }

    .article-card.inside-text .article-card-image {
        max-width: none;
        min-width: 0px;
    }

    .article-card a {
        display: flex;
    }

    .article-card .article-card-image img {
        width: 100%;
        height: calc(100vh - 600px);
        max-height: 150px;
        object-fit: cover;
    }

    .article-card.inside-text .article-card-image {
        min-height: calc(70vh - 100px);
    }
    
    .article-card.inside-text .article-card-image img {
        min-height: calc(70vh - 100px);
    }
}

@media (max-width: 800px) {
    .article-card.inside-text .article-card-image {
        min-height: calc(50vh - 100px);
    }
    
    .article-card.inside-text .article-card-image img {
        min-height: calc(50vh - 100px);
    }
}

@media (max-width: 500px) {
    .article-card .article-card-image  {
        max-width: 150px;
        min-width: 150px;
    }

    .article-card.inside-text .article-card-image {
        min-height: 33vh;
    }
    
    .article-card.inside-text .article-card-image img {
        min-height: 33vh;
    }
}