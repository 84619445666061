.article {
    padding: 40px 0;
    font-size: 18px;
}

.article .container {
    padding: 0 10px;
}

.article-header p {
    margin: 0;
}

.article-tag {
    color: var(--primary-color);
    text-transform: uppercase;
}

.article-body {
    line-height: 170%;
    margin-bottom: 30px;
}

.article-title {
    font-size: 62px;
    margin-bottom: 20px;
    font-weight: bold;
    line-height: 110%;
}

.article-subtitle {
    color: var(--secondary-font-color);
    font-size: 18px;
    font-weight: 400;
}

.article-info {
    padding: 20px 0;
}

.article-author {
    font-size: 14px;
    font-weight: bold;
    color: var(--primary-color);
}

.article-date {
    font-size: 12px;
    color: var(--primary-font-color);
}

.article-banner {
    margin-bottom: 20px;
}

.article-banner img {
    width: 100%;
    max-width: 950px;
}

.article-body p img {
    width: 100%;
    max-width: 950px;
}

.article-related-title,
.article-share-title {
    color: var(--primary-color);
    font-weight: bold;
}

.article-related {
    margin-bottom: 20px;
}

.article-related-tags {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow: hidden;
}

.article-related-tags a {
    border: 1px var(--primary-color) solid;
    padding: 8px 15px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-weight: bold;
    font-size: 14px;
}

.article-related-tags a:hover {
    background: var(--primary-color);
    color: var(--light-color);
}

.article-share-buttons {
    display: flex;
    gap: 25px;
}

@media (max-width: 800px) {
    .article-title {
        font-size: 40px;
    }

}