* {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans';
    box-sizing: border-box;
    color: #333333;
}

:root {
    --primary-font-color: #666666;
    --secondary-font-color: #555555;
    --light-font-color: #555555;
    --primary-color: #6a2c70;
    --light-color: #fff;
    --dark-color: #000;
    --background: #f7f7f7
}

body {
    background: var(--background);
}

.container {
    width: 100%;
    max-width: 1000px;
}

p {
    margin-bottom: 20px;
}

.content-center {
    display: flex;
    justify-content: center;
    width: 100%;
}

ul {
    list-style: none;
}

ul.pointer {
    list-style: disc inside;
}

ul.numeric {
    list-style: decimal inside;
}

ul li {
    cursor: pointer;
}

a {
    text-decoration: none;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
}

input {
    padding: 5px 10px;
}

.column-list {
    display: flex;
    flex-wrap: wrap;
}

.input-button {
    display: flex;
    gap: 8px;
    width: 350px;
}

.input-button button i {
    color: var(--primary-color);
}

.input-button input {
    width: 100%;
    font-size: 18px;
    border: 2px var(--primary-color) solid;
    border-radius: 5px;
    height: 40px;
    background: var(--background);
}

.input-button.internal {
    position: relative;
    display: flex;
    align-items: center;
}

.input-button.internal input {
    padding-right: 40px;
}

.input-button.internal button {
    position: absolute;
    right: 10px;
}

.pt-40 {
    padding-top: 40px;
}

/* ARTICLES */

.inline-article-list {
    margin-bottom: 20px;
}

.inline-article-list .article-list {
    display: flex;
    flex-wrap: wrap;
}

.article-list {
    width: 100%;
}

.inline-article-list .article-list-title {
    padding: 0 10px;
}

.inline-article-list .article-list .column {
    padding: 0 10px;
}

.column.col-1 {
    width: 8.33333%;
}

.column.col-2 {
    width: 16.66666%;
}

.column.col-3 {
    width: 25%;
}

.column.col-4 {
    width: 33.33333%;
}

.column.col-6 {
    width: 50%;
}

/* ARTICLE SEARCH RESULT */

.article-search-result {
    margin-bottom: 10px;
}

.article-search-result .column {
    padding: 10px;
}

.search-result-column {
    width: 65%;
}

.most-read-column {
    width: 35%;
}

.article-search-title {
    padding: 30px 10px;
}

.article-search-title strong {
    text-transform: uppercase;
}

.article-list-title {
    font-weight: bold;
    color: var(--primary-color);
    text-transform: uppercase;
}

.most-read-column .article-card-title {
    font-size: 15px;
    margin-bottom: 5px;
}

.info-content {
    padding: 40px 0 30px;
    font-size: 18px;
}

.info-content strong {
    color: var(--primary-color);
}

.info-content .container {
    padding: 0 10px;
}

.info-content h2 {
    color: var(--primary-color);
    margin-bottom: 20px;
}

.info-content ul {
    margin-bottom: 20px;
}

.info-content ul {
    cursor: auto;
}

.info-content ul li::marker {
    color: var(--primary-color);
}

@media (max-width: 1000px) {
    .article-list .column {
        width: 100%;
    }

    .column-list .column {
        width: 100%;
    }
}