.show-more-button {
    height: 50px;
    width: 100%;
    background: var(--primary-color);
    border-radius: 5px;
    color: var(--light-color);
    font-size: 15px;
    transition: all .1s ease;
}

.show-more-button:hover {
    transform: scale(1.01);
}