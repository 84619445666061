.footer {
    background: var(--primary-color);
    padding: 50px 0 0 0;
}

.footer .column-list {
    padding: 20px 10px;
}

.footer .column {
    width: 40%;
}

.footer .logo-column {
    margin-bottom: 20px;
}

.footer .logo {
    overflow: hidden;
}

.footer .logo {
    margin-left: -7px;
    margin-bottom: 10px;
}

.footer .list-title {
    color: var(--light-color);
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
}

.footer-links {
    display: flex;
    padding: 20px 0;
    gap: 20px;
}

.footer-links a svg {
    fill: var(--light-color);
}

.footer .footer-list {
    margin-bottom: 20px;
}

.footer .footer-list li {
    margin-bottom: 5px;
}

.footer .footer-list a {
    color: var(--light-color);
}

.footer .category-list {
    width: 16%;
    display: flex;
    justify-content: center;
}

.footer .util-links {
    width: 22%;
    display: flex;
    justify-content: center;
}

.footer .contact-list {
    width: 22%;
    display: flex;
    justify-content: center;
}

.foooter-botton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.foooter-botton span {
    padding: 30px 0 20px 0;
    width: 100%;
    text-align: center;
    color: #ffffffd6;
    font-size: 13px;
    line-height: 25px;
    border-top: 1px #ffffff26 solid;
}

@media (max-width: 800px) {
    .footer {
        padding: 30px 0 0 0;
    }

    .footer .column {
        width: 100%;
    }

    .footer .input-button {
        width: 100%;
    }

    .foooter-botton span {
        padding: 20px 0 20px 0;
        font-size: 11px;
    }
}

@media (max-width: 900px) {
    .footer .category-list {
        width: 100%;
        display: block;
    }

    .footer .util-links {
        width: 100%;
        display: block;
    }

    .footer .contact-list {
        width: 100%;
        display: block;
    }
}